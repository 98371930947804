import { Component, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {ApiService} from '../api.service'
import {Observable} from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import {SystemMessagesCardComponent} from 'dsio-ui-library';
import {SystemStatusCardComponent} from '../system-status-card/system-status-card.component';
import {SystemSupportCardComponent} from 'dsio-ui-library';
import {SystemCategoryCardComponent} from 'dsio-ui-library';
import { CustomKeycloakAuthService } from '../custom-keycloak-auth.service';
import { Router } from '@angular/router';
import {MatProgressBarModule} from '@angular/material/progress-bar'



export interface Status{
  solutionName: string,
  resources: Array<ResourceStatus>
}

export interface ResourceError{
  errorType: string,
  errorDescription: string

}
export interface ResourceStatus{
      resourceType: string,
      componentName: string,
      ownerId: string,
      errors?: Array<ResourceError>
}

@Component({
  selector: 'app-status',
  standalone: true,
  templateUrl: './status.component.html',
  imports: [MatButtonModule, SystemMessagesCardComponent, SystemStatusCardComponent, SystemSupportCardComponent, 
    MatProgressBarModule,SystemCategoryCardComponent,CommonModule],
  styleUrls: ['./status.component.css'],
})
export class StatusComponent implements OnInit{
  isloading:boolean = true
  solutions$!: Observable<Array<Status>|any> 
  @Output() solutions: any
  public token = sessionStorage.getItem("token") as string;
  public tenantId = sessionStorage.getItem("tenantId") as string;
  constructor(private apiService: ApiService, private readonly keycloak: KeycloakService,
    private customKeycloakAuthService: CustomKeycloakAuthService, router: Router){ 
  }

  async ngOnInit(): Promise<void> {

    // await this.customKeycloakAuthService.initializeKeycloak({ name: this.tenantId, clientId: 'websiteclient', displayName: null })
    const token = await this.customKeycloakAuthService.getToken();
    this.token= token; 
    sessionStorage.setItem("token", token);
    const tokenUpdated = await this.customKeycloakAuthService.updateToken2();
    if (tokenUpdated){
      console.log(`Token Updated`)
      sessionStorage.setItem("token", await this.customKeycloakAuthService.getToken())
    }else{
      console.log(`Token Not Updated`)
    }
      this.handleGetStatus()
  }


  async handleGetStatus() {
    //this.solutions$ = this.apiService.getResources(this.token, this.tenantId);
    this.solutions$ = this.apiService.getUsage(this.token, this.tenantId);
    await this.solutions$.subscribe( (solutions: any) => {
        this.isloading=false
         console.log("solutions component: " + solutions);
         this.solutions = solutions;
  })
}
}
