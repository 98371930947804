import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { provideAnimations } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { environment } from "../environments/environment";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCardModule } from "@angular/material/card";
import { MatMenuModule } from "@angular/material/menu";
import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from "@angular/material/dialog";
import {
  BaseChartDirective,
  provideCharts,
  withDefaultRegisterables,
} from "ng2-charts";
import { NgCircleProgressModule } from "ng-circle-progress";
import { CommonModule } from "@angular/common";
import { DsioUiLibraryModule } from "@kelyn-technologies/dsio-angular-lib";
import { RouterModule } from "@angular/router";
import {
  ApiModule,
  BASE_PATH,
  Configuration,
} from "@kelyn-technologies/dsio-angular-lib/dsio-api";

function initializeKeycloak(keycloak: KeycloakService) {
  return () => {
    const realm = sessionStorage.getItem("tenantId") as string;
    if (!realm) {
      return new Promise<boolean>((resolve) => resolve(false));
    }
    return keycloak.init({
      config: {
        realm: realm,
        url: `${environment.KEYCLOAK_URL}`,
        clientId: "websiteclient",
      },
      initOptions: {
        onLoad: "check-sso",
        silentCheckSsoRedirectUri:
          window.location.origin + "/assets/silent-check-sso.html",
      },
      enableBearerInterceptor: true,
      bearerExcludedUrls: ["/assets"],
      shouldUpdateToken: (request) => {
        return !(request.headers.get("token-update") === "false");
      },
    });
  };
}

export function apiConfiguration(): Configuration {
  const tenantId = sessionStorage.getItem("tenantId") as string;
  return new Configuration({
    credentials: {
      dsioTenantId: tenantId,
    },
  });
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    RouterModule,
    DsioUiLibraryModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatDialogModule,
    KeycloakAngularModule,
    BaseChartDirective,
    CommonModule,
    ApiModule.forRoot(apiConfiguration),
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "transparent",
      animationDuration: 300,
      showBackground: true,
      backgroundStrokeWidth: 16,
      backgroundStroke: "#F2F2F2",
      backgroundPadding: -8,
    }),
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    provideAnimations(),
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    provideCharts(withDefaultRegisterables()),
    { provide: BASE_PATH, useValue: environment.API_URL },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
