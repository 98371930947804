import { Component, EventEmitter, OnInit, Output,Input } from '@angular/core';
import { CustomKeycloakAuthService } from '../custom-keycloak-auth.service';
import { KeycloakService } from 'keycloak-angular';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import { Router } from "@angular/router";
import { ActionType } from '../app.component';
import {environment} from '../../environments/environment';
import {MatCardModule} from '@angular/material/card';


export interface Realm {
    displayName: string | null,
    clientId: string,
    name: string
}

@Component({
  selector: 'app-auth',
  standalone: true,
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css'],
  imports: [MatInputModule, MatFormFieldModule, MatButtonModule, MatCardModule],
  providers: [CustomKeycloakAuthService]
})
export class AuthComponent implements OnInit{

  public tenantId: string = '';
  public isLoggedIn = false;

  public keycloakAuthService: CustomKeycloakAuthService = new CustomKeycloakAuthService()
  constructor(private readonly keycloak: KeycloakService, private customKeycloakAuthService: CustomKeycloakAuthService, private router: Router) {

    let state = this.getParameterFromUrl("state")
    let realm  = sessionStorage.getItem("tenantId") as string
    let clientId = "websiteclient"

    if (state) {
        
            this.customKeycloakAuthService.initializeKeycloak({ name: realm, clientId: clientId, displayName: null })
                .then( () => {

                    // console.log('setting current realm')
                    this.customKeycloakAuthService.currentRealm = {
                        name: realm,
                        clientId: clientId, displayName: null
                    }
                    sessionStorage.setItem("tenantId", this.customKeycloakAuthService.currentRealm.name) 
                    this.customKeycloakAuthService.keycloakInstance.getToken().then( (token) => {
                        this.customKeycloakAuthService.currentToken = token;
                        sessionStorage.setItem("token", token)
                        sessionStorage.setItem("keycloakInstance", this.customKeycloakAuthService.keycloakInstance.toString())
                        this.router.navigate(["gateway/home/dashboard"])
                    })
                })

    }

}
ngOnInit(): void {
}

updateTenantId(event: any) {
  this.tenantId = event.target.value;

}


handleLoginPress() {
  this.login()  
}

login() {
    let realm = this.tenantId
    let clientId = 'websiteclient'
    if ( clientId && realm) {

            sessionStorage.setItem("tenantId",this.tenantId)
            this.customKeycloakAuthService.initializeKeycloak({ name: realm, clientId: clientId, displayName: null })
                .then( () => {
                    this.customKeycloakAuthService.keycloakInstance.login(
                        {
                            action: 'login',
                            redirectUri: `${environment.FRONTEND_URL}/gateway/auth?action=return&clientid=${clientId}&realm=${realm}`
                        }
                    ).then(function (authenticated:any) {
                            }
                        )
                })
        }
        
    }
   


 getParameterFromUrl(parameter: string) {
     let re = new RegExp(parameter + "=([a-z0-9-]+)\&?")
     let results = re.exec(window.location.href)

     if (results) {
         return results[1]
     } else {
         return ""
     }
 }

}