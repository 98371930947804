<div class="inner-container">
  <mat-card class="card p-15 reg-card">
    <mat-card-title class="card-header">
      <h2 class="mt-0 mb-5">DAR Registration</h2>
    </mat-card-title>
    <mat-card-content class="card-content">
      <div class="">
        <mat-card-title>
          <h5 class="mt-0 mb-10">Provisioning Details</h5>
        </mat-card-title>
          <form name="solutionInfoForm">
            <mat-form-field class="input">
              <mat-label>Region</mat-label>
              <mat-select name="SelectRegion" value="" required [disabled]="this.isSaved">
                  <mat-option *ngFor="let region of regions" [value]="region.value">
                    {{region.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="input mr-0">
                <mat-label>Storage Class</mat-label>
                <mat-select name="storageClass" value="" required [disabled]="this.isSaved">
                  <mat-option *ngFor="let storageClass of storageClasses" [value]="storageClass.value">
                    {{storageClass.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="input">
                <mat-label >Authcode</mat-label>
                <input matInput name="authCode" required [disabled]="this.isSaved">
              </mat-form-field>
              <mat-form-field class="input">
                <mat-label>Commvault Software Version</mat-label>
                <mat-select name="softwareVersion" value="" required [disabled]="this.isSaved">
                  <mat-option *ngFor="let softwareVersion of softwareVersions" [value]="softwareVersion.value">
                    {{softwareVersion.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="input mr-0">
                <mat-label >Capacity Requested (TB)</mat-label>
                <input matInput name="requestedCapacity" required [disabled]="this.isSaved">
              </mat-form-field>
            </form>
          </div>
    </mat-card-content>
    <div class="btn-group mb-15">
      <button mat-flat-button color="primary" class="secondary-btn" [disabled]="this.isSaved">Save</button>
      <button mat-flat-button color="primary" class="primary-btn" [disabled]="this.isSaved">Save & Enable</button>
    </div>
  </mat-card>
</div>