import { Component, OnInit, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DarSettings } from "@kelyn-technologies/dsio-angular-lib";
import { MatSnackBarModule, MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "solutions-wizard",
  standalone: true,
  templateUrl: "./solutions-wizard.component.html",
  imports: [CommonModule, MatSnackBarModule],
  styleUrls: ["./solutions-wizard.component.css"],
})
export class SolutionsWizardComponent implements OnInit {
  @Input() public solutionId = "";
  public formInputs = {};

  constructor(public snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.formInputs = {
      solutionId: this.solutionId,
      snackBar: this.snackBar,
      returnRoute: "/gateway/home/solutions",
    };
  }

  getContainer() {
    switch (this.solutionId) {
      case "DAR":
        return DarSettings;
      case "DARL":
        return DarSettings;
      default:
        return null;
    }
  }
}
