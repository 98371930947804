import { Component, AfterViewInit, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { RouterLink, RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { FormBuilder, FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';



interface Region {
  value: string;
  viewValue: string;
}

interface StorageClass {
  value: string;
  viewValue: string;
}

interface SoftwareVersion {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'dar-settings',
  templateUrl: './dar-settings.component.html',
  standalone: true,
  imports: [MatButtonModule, CommonModule, MatProgressBarModule, MatCardModule, MatFormFieldModule, MatSelectModule, MatInputModule ,RouterLink, RouterModule],
  styleUrls: ['./dar-settings.component.css']
})

// Component class
export class DarSettings implements AfterViewInit, OnInit {
    isloading:boolean = true
    public token = sessionStorage.getItem("token") as string;
    public tenantId = sessionStorage.getItem("tenantId") as string;
    public solutionInfoForm!: FormGroup;
    startingForm!: FormGroup;

    regions: Region[] = [
      { value: 'us-east-1', viewValue: 'US East (N. Virginia)' },
      { value: 'us-east-2', viewValue: 'US East (Ohio)' },
      { value: 'us-west-1', viewValue: 'US West (N. California)' },
      { value: 'us-west-2', viewValue: 'US West (Oregon)' },
    ]
  
    storageClasses: StorageClass[] = [
      { value: 's3ia', viewValue: 'S3 Infrequent Access' },
      { value: 's3standard', viewValue: 'S3 Standard' },
      { value: 'gir', viewValue: 'Glacier Infrequent Access' },
      { value: 'gda', viewValue: 'Glacier Deep Archive' },
    ]
  
    softwareVersions: SoftwareVersion[] = [
      { value: '11.32', viewValue: '11.32' },
      { value: '11.34', viewValue: '11.34' },
      { value: '11.36', viewValue: '11.36' },
    ]
    selectedRegion: string = "";
    isSaved: any = false;
    
    
    constructor(private _fb: FormBuilder) {
    }

    ngOnInit(): void {
      let tSaved: any = sessionStorage.getItem('isSaved');
      console.log(tSaved);
      this.isSaved = this.stringToBoolean(tSaved);
    }

    stringToBoolean(str: string): boolean {
      return str.toLowerCase() as unknown as boolean;
  }
        
        
      ngAfterViewInit(): void {
      }

      ngOnDestroy():void {
        this.isSaved = false;
        sessionStorage.setItem('isSaved', 'false');
      }
}