import { Component, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {ApiService} from '../api.service'
import {Observable} from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { UsageCardComponent } from 'dsio-ui-library';
import {CustomKeycloakAuthService} from '../custom-keycloak-auth.service';
import {CommonModule} from '@angular/common';
import {MatProgressBarModule} from '@angular/material/progress-bar'


export interface Usage{
  sku: String,
  usage: String,
  month: String,
  purchased:Boolean
}

@Component({
  selector: 'app-usage',
  standalone: true,
  templateUrl: './usage.component.html',
  imports:[MatButtonModule, UsageCardComponent, CommonModule, MatProgressBarModule],
  styleUrls: ['./usage.component.css'],
})
export class UsageComponent implements OnInit{
  entitlements$!: Observable<any>
  isloading:boolean = true
  @Output() public entitlements: any;
  constructor(private apiService: ApiService, private readonly keycloak: KeycloakService,  private customKeycloakAuthService: CustomKeycloakAuthService,){
  }

  async ngOnInit(): Promise<void> {

    // await this.customKeycloakAuthService.initializeKeycloak({ name: this.tenantId, clientId: 'websiteclient', displayName: null })
    const token = await this.customKeycloakAuthService.getToken();
    this.token= token; 
    sessionStorage.setItem("token", token);
    const tokenUpdated = await this.customKeycloakAuthService.updateToken2();
    if (tokenUpdated){
      console.log(`Token Updated`)
      sessionStorage.setItem("token", await this.customKeycloakAuthService.getToken())
    }else{
      console.log(`Token Not Updated`)
    }
    this.getEntitlements()
  }

  public token = sessionStorage.getItem("token") as string;
  public tenantId = sessionStorage.getItem("tenantId") as string;
  async getEntitlements() {
    this.entitlements$ = this.apiService.getUsage(this.token, this.tenantId)
    this.entitlements$.subscribe((entitlements: any) => {
      this.entitlements = entitlements;
      this.isloading=false
      // console.log(this.entitlements)
    });

  }
}

