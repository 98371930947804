<div class="inner-container">
  <mat-progress-bar *ngIf="isloading" mode="indeterminate" value="80" class="mb-15"> </mat-progress-bar>
  <!-- <button mat-flat-button color="primary" class="primary-btn" routerLink="solutions-wizard/dar-settings">
   Configure
 </button> -->
  <table mat-table [dataSource]="dataSource" matSort>
    <!-- Solution Name Column -->
    <ng-container matColumnDef="solutionName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.status }}</td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.status.toLowerCase() === 'configured'">
          <mat-slide-toggle
            class="mat-accent"
            [checked]="isEnabled(element)"
            [labelPosition]="'after'"
            [hideIcon]="true"
            [disabled]="!canToggleState(element)"
            (change)="changeStatus($event, element.solution_id)"
          >
            <p class="label">Enabled</p>
          </mat-slide-toggle>
        </ng-container>
        <ng-container *ngIf="element.status.toLowerCase() === 'available'">
          <button
            mat-flat-button
            color="primary"
            class="primary-btn"
            routerLink="./{{ element.solution_id }}"
            routerLinkActive="active"
            ariaCurrentWhenActive="page"
            #darsettings="routerLinkActive"
          >
            Configure
          </button>
        </ng-container>
        <ng-container *ngIf="element.status.toLowerCase() != 'available'">
          <button
            mat-flat-button
            color="primary"
            routerLink="./{{ element.solution_id }}"
            class="secondary-solid-btn"
            routerLinkActive="active"
            ariaCurrentWhenActive="page"
            #darsettings="routerLinkActive"
          >
            View
          </button>
        </ng-container>
      </td>
    </ng-container>
    <tr class="header-shadow" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
