<div class="inner-container">
  <mat-progress-bar *ngIf="isloading" 
    mode="indeterminate" value="80">
  </mat-progress-bar>
  <div class="upper-container">
    <mat-card class="card status mr-20">
      <mat-card-title>
        <div class="card-header">
          <img src="../../assets/statusIcon.png" width="46" height="46" />
          <h2 style="margin-left: 10px;">Status</h2>
          <div class="status-container">
            <ng-container *ngIf="this.errorCount > 0">
              <img src="assets/danger-circle.png" height="35px" width="35px" />
            </ng-container>
            <ng-container *ngIf="this.errorCount === 0">
              <img src="assets/tick-circle.png" height="35px" width="35px" />
            </ng-container>
          </div>
        </div>
      </mat-card-title>
      <mat-card-content>
        <hr class="seperater" />
        <div class="status-info">
          <ul class="notATable">
            <li><label>Issues:</label>
              <ng-container *ngIf="this.down===0">
              <p class="data">0 Issues</p>
              </ng-container>
              <ng-container *ngIf="this.down===1">
                <p class="data">1 Issue</p>
              </ng-container>
              <ng-container *ngIf="this.down> 1">
                <p class="data">{{this.down}} Issues</p>
              </ng-container>
            </li>
            <li><label>Status Detail:</label>
              <div><p class="data">Detail</p></div>
            </li>
         </ul>
          <!-- <button mat-flat-button color="primary" (click)="goToStatus()"
            mat-flat-button color="primary" class="primary-btn">Full Status</button> -->
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="card">
      <mat-card-title class="card-header">
        <img src="../../assets/usage.png" width="46" height="46" />
        <h2 style="line-height: 0.25;margin-left: 10px;">Usage by Solution</h2>
      </mat-card-title>
        <mat-card-content>
          <div class="circle-graph-container">
            <ng-container *ngFor="let top3Usage of top3Usage; let i=index">
              <ng-container *ngIf="i < 3">
                <app-pie-graph [top3Usage]="top3Usage" title={{top3Usage.dimension_name}} [fontSize]="'40'"></app-pie-graph>
              </ng-container>
          </ng-container>
        </div>
      </mat-card-content> 
    </mat-card>
  </div>
  <div class="flex-parent-row">
    <div class="col-50-flex mr-10">
      <div class="bar-graph">
        <mat-card class="card graph">
          <mat-card-title>
            <div class="card-header mb-15">
              <img src="../../assets/dashboard.png" width="46" height="46" />
              <h2 style="margin-left: 10px;">Usage Metrics by Consumption (TB)</h2>
            </div>
          </mat-card-title>
          <mat-card-content>
            <app-bar-graph [ytdUsage]="ytdUsage" type="consumption"></app-bar-graph>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="col-50-flex ml-10">
      <div class="bar-graph">
        <mat-card class="card graph">
          <mat-card-title>
            <div class="card-header mb-15">
              <img src="../../assets/dashboard.png" width="46" height="46" />
              <h2 style="margin-left: 10px;">Usage Metrics by Count</h2>
            </div>
          </mat-card-title>
          <mat-card-content>
            <app-bar-graph [ytdUsage]="ytdUsage" type="count"></app-bar-graph>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>