import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import {CustomKeycloakAuthService} from './custom-keycloak-auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http:HttpClient, private customKeycloakAuthService: CustomKeycloakAuthService) {
    
  }

  async updateToken(){
    if(await this.customKeycloakAuthService.updateToken()){
      sessionStorage.setItem('token', await this.customKeycloakAuthService.getToken());
    }
  }

  getUsers(token: string, tenantId: string) {
    this.updateToken();
    return this.http.get(`${environment.API_URL}/users/?tenantId=${tenantId}`,{headers: {Authorization: `Bearer ${token}`}});
  }

  addUser(token: string, user: any, tenantId: string) {
    this.updateToken();
    return this.http.post(`${environment.API_URL}/users/?tenantId=${tenantId}`,
    {
      "username": user.userName,
      "enabled": true,
      "totp": false,
      "emailVerified": true,
      "firstName": user.firstName,
      "lastName": user.lastName,
      "email": user.emailAddress,
      "attributes": {
          "tenantId": [
              tenantId
          ]
      },
      "disableableCredentialTypes": [],
      "requiredActions": [],
      "notBefore": 0,
      "access": {
          "view": true
     
      }
  },
    {headers: {Authorization: `Bearer ${token}`}});
  }

  editUser(token: string, user: any, tenantId: string) {
    this.updateToken();

    return this.http.patch(`${environment.API_URL}/users/${user.userId}/?tenantId=${tenantId}`,
    {
      email: user.emailAddress,
      firstName: user.firstName,
      lastName: user.lastName
    },
    {headers: {Authorization: `Bearer ${token}`}});
  }

  getUserRole(token: string, userId: String, tenantId: string) {
    this.updateToken();
    return this.http.get(`${environment.API_URL}/users/${userId}/roles?tenantId=${tenantId}`,{headers: {Authorization: `Bearer ${token}`}});
  }

  updateUserRole(token: string, user:any, tenantId: string) {
    this.updateToken();
    return this.http.post(`${environment.API_URL}/users/${user.userId}/roles/?tenantId=${tenantId}`,
    {"tenant_admin": user.isAdmin},
    {headers: {Authorization: `Bearer ${token}`}});
  }
  getSolutions(token: string, tenantId: string) {
    this.updateToken();
    return this.http.get(`${environment.API_URL}/solutions/?tenantId=${tenantId}`,{headers: {Authorization: `Bearer ${token}`}});
  }

  getTenantSolutions(token: string, tenantId: string) {
    this.updateToken();
    return this.http.get(`${environment.API_URL}/solutions/${tenantId}/?tenantId=${tenantId}`,{headers: {Authorization: `Bearer ${token}`}})
  }

  updateTenantSolutions(token: string, solutionId: string, tenantId: string, enabled: boolean) {
    this.updateToken();
    return this.http.patch(`${environment.API_URL}/tenant_solutions/${solutionId}/?tenantId=${tenantId}`,
    {"enabled": enabled, "tenantId": tenantId},
    {headers: {Authorization: `Bearer ${token}`}});
  }

  getUsage(token: string, tenantId: string) {
    this.updateToken();
    return this.http.get(`${environment.API_URL}/usage/${tenantId}?tenantId=${tenantId}`,{headers: {Authorization: `Bearer ${token}`}});
  }

  getTop3Usage(token: string, tenantId: string) {
    this.updateToken();
    return this.http.get(`${environment.API_URL}/usage/${tenantId}/top3?tenantId=${tenantId}`,{headers: {Authorization: `Bearer ${token}`}});
  }

  getYtdUsage(token: string, tenantId: string) {
    this.updateToken();
    return this.http.get(`${environment.API_URL}/usage/${tenantId}/ytd?tenantId=${tenantId}`,{headers: {Authorization: `Bearer ${token}`}});
  }



  getEntitlements(token: string, tenantId: string) {
    this.updateToken();
    return this.http.get(`${environment.API_URL}/entitlements/${tenantId}/?tenantId=${tenantId}`,{headers: {Authorization: `Bearer ${token}`}});
  }
  getResources(token: string, tenantId: string) {
    this.updateToken();
    return this.http.get(`${environment.API_URL}/resources/?tenantId=${tenantId}`,{headers: {Authorization: `Bearer ${token}`}});
  }
  getIDP(token: string, tenantId: string) {
    return this.http.get(`${environment.API_URL}/externalauth/?tenantId=${tenantId}`,{headers: {Authorization: `Bearer ${token}`}});
  }
  addIDP(token: string, samlIdp: any, tenantId: string): Observable<any> {
    this.updateToken();
    return this.http.patch(`${environment.API_URL}/externalauth/?tenantId=${tenantId}`,
    {
      fromUrl: samlIdp
    },
    {headers: {Authorization: `Bearer ${token}`}});
  }
  updateIDP(token: string, samlIdp: any, tenantId: string): Observable<any> {
    this.updateToken();
    return this.http.post(`${environment.API_URL}/externalauth/?tenantId=${tenantId}`,
    {
      fromUrl: samlIdp
    },
    {headers: {Authorization: `Bearer ${token}`}});
  }


}
