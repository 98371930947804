import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { UsageComponent } from "./usage/usage.component";
import { StatusComponent } from "./status/status.component";
import { SolutionsComponent } from "./solutions/solutions.component";
import { UserManagementComponent } from "./user-management/user-management.component";
import { UserComponent } from "./user/user.component";
import { ExternalAuthComponent } from "./external-auth/external-auth.component";
import { HomeComponent } from "./home/home.component";
import { AuthComponent } from "./auth/auth.component";
import { AppComponent } from "./app.component";
import { keycloakFunctionalGuard } from "./keycloak-auth-guard";
import { SolutionsWizardComponent } from "./solutions/solutions-wizard/solutions-wizard.component";

const routes: Routes = [
  { path: "", redirectTo: "gateway/auth", pathMatch: "full" },
  {
    path: "gateway",
    component: AppComponent,
    children: [
      {
        path: "home",
        redirectTo: "home/solutions",
        pathMatch: "full",
        data: { breadcrumb: "Solutions" },
      },
      {
        path: "home",
        component: HomeComponent,
        canActivate: [keycloakFunctionalGuard],
        children: [
          // {
          //   path: "dashboard",
          //   component: DashboardComponent,
          //   data: { breadcrumb: "Dashboard" },
          //   canActivate: [keycloakFunctionalGuard],
          // },
          {
            path: "usage",
            component: UsageComponent,
            data: { breadcrumb: "Usage" },
            canActivate: [keycloakFunctionalGuard],
          },
          {
            path: "status",
            component: StatusComponent,
            data: { breadcrumb: "Status" },
            canActivate: [keycloakFunctionalGuard],
          },
          {
            path: "solutions",
            component: SolutionsComponent,
            data: { breadcrumb: "Solutions" },
            canActivate: [keycloakFunctionalGuard],
          },
          {
            path: "solutions/configure/:solutionId",
            data: { breadcrumb: "Configure" },
            component: SolutionsWizardComponent,
            canActivate: [keycloakFunctionalGuard],
          },
          {
            path: "user-management",
            component: UserManagementComponent,
            data: { breadcrumb: "User Management" },
            canActivate: [keycloakFunctionalGuard],
          },
          {
            path: "user",
            component: UserComponent,
            data: { breadcrumb: "Users" },
            canActivate: [keycloakFunctionalGuard],
          },
          {
            path: "external-auth",
            component: ExternalAuthComponent,
            data: { breadcrumb: "External Authorization" },
            canActivate: [keycloakFunctionalGuard],
          },
        ],
      },
      { path: "auth", component: AuthComponent },
    ],
  },
  { path: "auth", component: AuthComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
