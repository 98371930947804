import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import { inject } from "@angular/core";

export function keycloakFunctionalGuard(
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Promise<boolean> {
  const keycloak = inject(KeycloakService);
  const router = inject(Router);
  const tenantId = sessionStorage.getItem("tenantId");

  return new Promise(async (resolve, reject) => {
    if (!tenantId) {
      // The user has skipped the tenant selection page, lets take them there.
      router.navigate(["gateway/auth"]);
    }
    // if tenantId is set is session storage then ensure logged in.
    // Tenant id is set from the preauth page and should follow the user.
    if (!(await keycloak.isLoggedIn())) {
      await keycloak.login();
      return resolve(false);
    }
    resolve(true);
  });
}
