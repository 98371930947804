import { AfterViewInit, OnInit,Component, ViewChild, Inject, ElementRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {KeycloakService} from 'keycloak-angular'
import {ApiService} from '../api.service'
import {Observable, map} from 'rxjs';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSlideToggleChange, MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {MatDialog,
  MatDialogModule, MatDialogRef, MAT_DIALOG_DATA
  } from '@angular/material/dialog';
import {CommonModule} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {BooleanInput} from '@angular/cdk/coercion';
import {CustomKeycloakAuthService} from '../custom-keycloak-auth.service';
import {MatProgressBarModule} from '@angular/material/progress-bar'
import {
  MatSnackBar,
  MatSnackBarAction,
  MatSnackBarActions,
  MatSnackBarLabel,
  MatSnackBarModule,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

export interface User {
  userId: String,
  firstName: String,
  lastName: String,
  emailAddress: String,
  userName: String,
  isAdmin: BooleanInput,
}



@Component({
  selector: 'app-user-management',
  standalone: true,
  imports: [MatButtonModule, MatTableModule, MatSlideToggleModule, MatSortModule, CommonModule,
    MatProgressBarModule ,MatDialogModule,MatSnackBarModule],
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements AfterViewInit, OnInit {
  users$!: Observable<Array<User>|any> 
  role$!: Observable<any> 
  isloading:boolean = true
  newId$!: Observable<any>;

  token: string = sessionStorage.getItem("token") as string;

  tenantId: string = sessionStorage.getItem("tenantId") as string;
  isLoggedIn = false;
  USER_DATA: User[] = [];
  displayedColumns: string[] = ['userName', 'emailAddress', 'lastName', 'isAdmin'];
  dataSource = new MatTableDataSource<any>();

   editUserId: String = '';
   editUsername: String = '';
   editEmailAddress: String = '';
   editFirstName: String = '';
   editLastName: String = '';
   editIsAdmin: Boolean = false;
   
  
  public selectedRow:String = '1';
  // constructor(public dialog: MatDialog){}
  constructor(private apiService: ApiService, private readonly keycloak: KeycloakService, public dialog: MatDialog, 
    public customKeycloakAuthService: CustomKeycloakAuthService, private _snackBar: MatSnackBar){
  }

  @ViewChild(MatSort) sort!: MatSort

  async ngOnInit(): Promise<void> {

    // await this.customKeycloakAuthService.initializeKeycloak({ name: this.tenantId, clientId: 'websiteclient', displayName: null })
    const token = await this.customKeycloakAuthService.getToken();
    this.token= token; 
    sessionStorage.setItem("token", token);
    const tokenUpdated = await this.customKeycloakAuthService.updateToken2();
    if (tokenUpdated){
      sessionStorage.setItem("token", await this.customKeycloakAuthService.getToken())
    }else{
      console.log(`Token Not Updated`)
    }
    this.handleGetUsers();
  }

  ngAfterViewInit(): void {
  this.dataSource.sort = this.sort;
  }

  preventToggle(event: MatSlideToggleChange, element: any) {
    event.source.checked = element.isAdmin;
  }

  
  setSelected(row: User):void {
    this.selectedRow = row.userId;
    this.editUserId = row.userId;
    this.editUsername = row.userName;
    this.editEmailAddress = row.emailAddress;
    this.editFirstName = row.firstName;
    this.editLastName = row.lastName;
    this.editIsAdmin = row.isAdmin as Boolean;
  }

  getSelected(userId: String):User {
    let user:User = {userId: '', emailAddress: '', userName:'', firstName: '', lastName:'', isAdmin: false};
    this.USER_DATA.forEach((row:User) => {
      if(row.userId === userId) {
        user = row;
      }
    });
    return user;
  }

  handleAddNewUserDialog(): void {
    const dialogRef = this.dialog.open(AddUserDialog, {
      width: '342px',
      data: {userId: '', userName: '', emailAddress:'', firstName:'', lastName:'', isAdmin: false}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.handleAddUser(result)
  })
}

  handleEditUserDialog(userId:String):void {
    const dialogRef = this.dialog.open(EditUserDialog, {
      width: '342px',
      data: {userId: this.editUserId, userName: this.editUsername, emailAddress:this.editEmailAddress, firstName:this.editFirstName, lastName:this.editLastName, isAdmin: this.editIsAdmin}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.handleEditUser(result);
      this.handleUpdateRole(result);
      this.USER_DATA.forEach(row=> {
        if(row.userId === result.userId) {
          row.userName = result.userName;
          row.emailAddress = result.emailAddress;
          row.firstName = result.firstName;
          row.lastName = result.lastName;
          row.isAdmin = result.isAdmin;
        }
      })
    });
  }

  


  async handleAddUser(user:any): Promise<void> {
    this.newId$ = await this.apiService.addUser(this.token, user, this.tenantId);
    await this.newId$.subscribe((data: any) => {
      // console.log('Post Call Successful', data);
      this.handleUpdateRole(user);
      this._snackBar.open("User Added", "close")
      user.userId = data;
      console.table(user)
      this.USER_DATA.push(user);
      this.dataSource.data = this.USER_DATA;
    })
  }


  async handleEditUser(data:any):Promise<void> {
    this.apiService.editUser(this.token, data, this.tenantId).subscribe((data: any) => {;
      this._snackBar.open("User Updated", "close")
      // console.log('Patch Call Successful', data);
    })  
  }

  async getRole(userId: String, index:number) {
   this.role$ = this.apiService.getUserRole(this.token, userId, this.tenantId);
      await this.role$.subscribe((data: any) => {
        if(data.length> 0) {
        this.USER_DATA[index].isAdmin = true;
      }
      })
      return this.role$
}

async handleUpdateRole(data:User): Promise<void> {
  this.apiService.updateUserRole(this.token, data, this.tenantId).subscribe((data: any) => {
    this._snackBar.open("Role Updated", "close")
    // console.log('Patch Call Successful', data);
  })  
}


  async handleGetUsers() {
    this.isloading=true
    if (this.USER_DATA.length > 0 ){
      this.USER_DATA = [];
    }
    this.users$ = this.apiService.getUsers(this.token, this.tenantId);
      await this.users$.subscribe((data: any) => {
        this.isloading=false
        
        data.forEach(async (user: any, index: number) => {
          let newuser = {userId: user.id, emailAddress: user.email, userName: user.username, firstName: user.firstName, lastName: user.lastName, isAdmin: false};
          if (user.attributes?.isAdmin) {
            newuser.isAdmin = true;
          } else {
            newuser.isAdmin = false;
          }
          this.USER_DATA.push(newuser);
          this.getRole(user.id, index);
      })
      this.dataSource.data = this.USER_DATA;
  })
}
}

@Component({
  selector: 'dsio-add-user-dialog',
  templateUrl: './dsio-add-user-dialog.html',
  styleUrls: ['./user-management.component.css'],
  standalone: true,
  imports: [MatButtonModule, MatDialogModule, CommonModule, MatSelectModule, MatInputModule, MatFormFieldModule,MatSlideToggleModule],
})
export class AddUserDialog {

  @ViewChild('usernameInput') usernameInput!: ElementRef;
  @ViewChild('emailInput') emailInput!: ElementRef;
  @ViewChild('firstNameInput') firstNameInput!: ElementRef;
  @ViewChild('lastNameInput') lastNameInput!: ElementRef;
  constructor(public dialogRef: MatDialogRef<AddUserDialog>,@Inject(MAT_DIALOG_DATA) public data: any,) {}

  addUser(data: any):void {
    data.userName = this.usernameInput.nativeElement.value;
    data.firstName = this.firstNameInput.nativeElement.value;
    data.lastName = this.lastNameInput.nativeElement.value;
    data.emailAddress = this.emailInput.nativeElement.value;
    data.isAdmin = this.data.isAdmin;
    this.dialogRef.close(data);
  }

  updateAdmin(data: any):void {
    this.data.isAdmin = !this.data.isAdmin;
  }

}

@Component({
  selector: 'dsio-edit-user-dialog',
  templateUrl: './dsio-edit-user-dialog.html',
  styleUrls: ['./user-management.component.css'],
  standalone: true,
  imports: [MatButtonModule, MatDialogModule, CommonModule, MatSelectModule, MatInputModule, MatFormFieldModule, MatSlideToggleModule],
})
export class EditUserDialog {

  role$!: Observable<any> 
  @ViewChild('usernameInput') usernameInput!: ElementRef;
  @ViewChild('emailInput') emailInput!: ElementRef;
  @ViewChild('firstNameInput') firstNameInput!: ElementRef;
  @ViewChild('lastNameInput') lastNameInput!: ElementRef;

  constructor(public dialogRef: MatDialogRef<EditUserDialog>,@Inject(MAT_DIALOG_DATA) public data: User, ) {

  }
  async updateUser(data: any):Promise<void> {
    data.firstName = this.firstNameInput.nativeElement.value;
    data.lastName = this.lastNameInput.nativeElement.value;
    data.emailAddress = this.emailInput.nativeElement.value;

    this.dialogRef.close(data);
  }
  

  updateAdmin(data: any):void {
    this.data.isAdmin = !this.data.isAdmin;
  }
}

