<div class="inner-container">
  <mat-progress-bar *ngIf="isloading" 
      mode="indeterminate" value="80" class="mb-15">
    </mat-progress-bar>
  <div class="btn-group">
      <button mat-flat-button (click)="handleEditUserDialog(this.selectedRow)" color="primary" class="secondary-btn mb-15">
        Edit User
      </button>
      <button mat-flat-button (click)="handleAddNewUserDialog()" color="primary" class="primary-btn mb-15">
        New User
      </button>
  </div>
  
  <table mat-table [dataSource]="dataSource" matSort>
    <!-- Username Column -->
    <ng-container matColumnDef="userName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
      <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="emailAddress">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email Address </th>
      <td mat-cell *matCellDef="let element"> {{element.emailAddress}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.firstName}} {{element.lastName}}</td>

    </ng-container>

    <!-- IsAdmin Column -->
    <ng-container matColumnDef="isAdmin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Admin </th>
      <td mat-cell *matCellDef="let element">
        <mat-slide-toggle class="mat-accent" [checked]=" element.isAdmin" [hideIcon]="true"
          (change)="preventToggle($event, element)"></mat-slide-toggle>
      </td>
    </ng-container>

    <tr class="header-shadow" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="setSelected(row)"
      [ngClass]="{selecetedRow: selectedRow===row.userId}"></tr>
    </table>
</div>