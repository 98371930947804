import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {ApiService} from '../api.service'
import {Observable} from 'rxjs';
import {MatCardModule} from '@angular/material/card';
import { KeycloakService } from 'keycloak-angular';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar'




export interface User {
  userId: String,
  firstName: String,
  lastName: String,
  emailAddress: String,
  userName: String,
}
@Component({
  selector: 'app-user',
  standalone: true,
  templateUrl: './user.component.html',
  imports: [MatButtonModule, MatCardModule, MatInputModule, MatFormFieldModule, FormsModule, 
    MatProgressBarModule,MatIconModule],
  styleUrls: ['./user.component.css']
})


export class UserComponent {
  isloading:boolean = true
  public hide:Boolean = true;

  users$!: Observable<Array<User>|any> 
  constructor(private apiService: ApiService, private readonly keycloak: KeycloakService){
  }





  // async handleGetUsers() {
  //     this.users$ = this.apiService.getUsers(await this.keycloak.getToken());
  //     await this.users$.subscribe({
  //       next(response) {
  //         response.forEach((user:User) => {
  //           alert(JSON.stringify(user));
  //         });
        
  //       },
  //       error(err) {
  //         console.log(err)
  //       },
  //     })
  // }

}
