import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { Realm } from "./auth/auth.component";
import { environment } from '../../src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CustomKeycloakAuthService {

    DELAY_MS = 1000

    private _keycloakInstance: KeycloakService
    private _currentRealm: Realm

    public _currentToken: string = ''

    constructor() {
        this._keycloakInstance = new KeycloakService()
        this._currentRealm = {
            name: "",
            displayName: "",
            clientId: ""
        }
    }

    get keycloakInstance(): KeycloakService {
        return this._keycloakInstance;
    }

    get currentRealm(): Realm {
        return this._currentRealm;
    }


    get currentToken(): string {
        return this._currentToken;
    }

    set currentRealm(value: Realm) {
        this._currentRealm = value;
    }

    set currentToken(value: string) {
        this._currentToken = value;
    }

    public initializeKeycloak(realm: Realm): Promise<boolean> {
        // console.log('initializing keycloak')
        return this._keycloakInstance.init({
            config: {
                url: environment.KEYCLOAK_URL,
                realm: realm.name,
                clientId: 'websiteclient',
            },
            initOptions: {
                onLoad: "login-required",
                responseMode: "query"
            },
            enableBearerInterceptor: true,
            bearerExcludedUrls: ['/assets'],
            shouldUpdateToken: (request) => {
                return !(request.headers.get('token-update') === 'false');
            }
        });
    }

    public initializeKeycloakPostLogin(realm: Realm) {

    }

    public isLoggedInWithDelay(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this._keycloakInstance.isLoggedIn()
            }, this.DELAY_MS)
        });
    }

    public updateToken(): Promise<boolean | string> {
        return new Promise((resolve, reject) => {
            if (this._keycloakInstance.isTokenExpired(40)) {
                this._keycloakInstance.updateToken().then(resolve, reject);
            } else {
                resolve('Token is not expired')
            }
        });
    }


    public getToken(): Promise<string> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this._keycloakInstance.getToken().then((token: string) => {
                    // console.log('is logged in: ' + isLoggedIn)
                    resolve(token)

                }, reject);
            }, this.DELAY_MS)
        });
    }

    public updateToken2(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            // if(this._keycloakInstance.isTokenExpired()){
            this._keycloakInstance.updateToken().then((tokenUpdated: boolean) => {
                resolve(tokenUpdated)
            }, reject);
            // }
        });
    }

}
