import {CommonModule} from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import { Status } from '../status/status.component';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import { Solution } from '@kelyn-technologies/dsio-angular-lib/dsio-api';

@Component({
  selector: 'app-system-status-card',
  standalone: true,
  templateUrl: './system-status-card.component.html',
  imports: [MatCardModule, MatExpansionModule, CommonModule],
  styleUrls: ['./system-status-card.component.css']
})
export class SystemStatusCardComponent implements OnInit{

  solutions$!: Observable<Array<Status>> 
  @Input() solutions: Solution[] = [];
  errorCount = 0;
  errors = 0;  
  down = 0;

  total=0;
  public isExpanded:boolean = false;
  issue = "";
  footer= "";


  public tenantId = sessionStorage.getItem("tenantId") as string;

  constructor() { 
  }

ngOnInit(): void {
  this.handleGetStatus()
  
}
  handleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  async handleGetStatus() {
  }
  
}
