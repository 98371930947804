import { Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCardModule } from "@angular/material/card";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { ExternalAuth } from "./external-auth";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";

@Component({
  selector: "app-external-auth",
  standalone: true,
  templateUrl: "./external-auth.component.html",
  imports: [
    FormsModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    CommonModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  styleUrls: ["./external-auth.component.css"],
})
export class ExternalAuthComponent implements OnInit {
  public tenantId: string = sessionStorage.getItem("tenantId") as string;

  constructor(
    private router: Router,
    //private apiService: ApiService,
    private _snackBar: MatSnackBar
  ) {}
  isLoggedIn: boolean = false;
  update: boolean = false;
  isShown: boolean = false;
  keycloakUrl =
    environment.KEYCLOAK_URL +
    "/realms/" +
    this.tenantId +
    "/brokers/dsio/endpoint/descriptor";
  externalAuth: ExternalAuth = new ExternalAuth(this.keycloakUrl, "");

  async ngOnInit(): Promise<void> {
    //this.handleGetIDP();
  }

  async handleGetIDP() {
    /*
    let idp = this.apiService.getIDP(this.tenantId);
    await idp.subscribe((idpResult: any) => {
      // console.log(idpResult);
      idp = idpResult;
      if (idpResult) {
        this.externalAuth.entityDescriptor =
          "Authentication Created: Enter new url to update";
        this.update = true;
      }
    });
    */
  }

  async addIdp() {
  /*
    let idp: any;
    if (!this.update) {
      idp = this.apiService
        .addIDP(this.externalAuth.entityDescriptor, this.tenantId)
        .subscribe((idp) => {
          this.isShown = true;
          // console.log(idp);
          this.externalAuth.entityDescriptor =
            "Authentication Created: Enter new url to update";
          this._snackBar.open("idp added", "close");
        });
    } else {
      idp = this.apiService
        .updateIDP(this.externalAuth.entityDescriptor, this.tenantId)
        .subscribe((idp) => {
          this.isShown = true;
          // console.log(idp);
          this.externalAuth.entityDescriptor =
            "Authentication Created: Enter new url to update";
          this._snackBar.open("idp updated", "close");
        });
    }
    await idp.subscribe((idpResult: any) => {}); */
  } 
}
