import { CommonModule } from "@angular/common";
import { Component, ViewChild, AfterViewInit, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from "@angular/material/slide-toggle";
import { MatSort, MatSortModule } from "@angular/material/sort";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { Router, RouterLink, RouterModule } from "@angular/router";
import {
  SolutionService,
  Solution,
  UpdateSolutionRequest,
} from "@kelyn-technologies/dsio-angular-lib/dsio-api";

@Component({
  selector: "app-solutions",
  standalone: true,
  templateUrl: "./solutions.component.html",
  imports: [
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    CommonModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    RouterLink,
    RouterModule,
  ],
  styleUrls: ["./solutions.component.css"],
})
export class SolutionsComponent implements AfterViewInit, OnInit {
  displayedColumns: string[] = ["solutionName", "status", "action"];
  isloading: boolean = true;
  public tenantId = sessionStorage.getItem("tenantId") as string;
  solutionData: Solution[] = [];
  dataSource = new MatTableDataSource(this.solutionData);

  constructor(
    private readonly solutionService: SolutionService,
    public router: Router
  ) {}

  @ViewChild(MatSort) sort!: MatSort;

  async ngOnInit(): Promise<void> {
    this.handleGetSolutions();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  async handleGetSolutions() {
    this.solutionService.getSolutions().subscribe((response) => {
      this.solutionData = response.solutions;
      this.dataSource.data = this.solutionData;
      this.isloading = false;
    });
  }

  changeStatus(event: MatSlideToggleChange, solutionId: string) {
    const action = event.checked ? "enable" : "disable";
    this.solutionService
      .updateSolution(solutionId, { action: action } as UpdateSolutionRequest)
      .subscribe((response) => {
        const status = response.solution.status;
        this.solutionData.forEach((solution) => {
          if (solution.solution_id === solutionId) {
            solution.status = status;
          }
        });
        this.dataSource.data = this.solutionData;
      });
  }

  isEnabled(solution: Solution) {
    switch (solution.status.toLowerCase()) {
      case "enabled":
        return true;
      case "enabling":
        return true;
      case "disabling":
        return true;
      case "unavailable":
        return true;
      default:
        return false;
    }
  }

  canToggleState(solution: Solution) {
    switch (solution.status.toLowerCase()) {
      case "configured":
        return true;
      default:
        return false;
    }
  }
}
