import { Component, OnInit } from "@angular/core";
import { environment } from "../../environments/environment";
import { RouterModule, Router } from "@angular/router";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCardModule } from "@angular/material/card";
import { MatMenuModule } from "@angular/material/menu";
import { CommonModule } from "@angular/common";
import { KeycloakService } from "keycloak-angular";
import { KeycloakProfile } from "keycloak-js";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
  selector: "app-home",
  standalone: true,
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  imports: [
    RouterModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    MatProgressSpinnerModule,
  ],
})
export class HomeComponent implements OnInit {
  public userProfile: KeycloakProfile | null = null;
  public firstName: string | undefined = "";

  environment = environment;

  public tenantId: string = sessionStorage.getItem("tenantId") as string;
  constructor(
    private readonly keycloak: KeycloakService,
    private router: Router
  ) {}

  async ngOnInit() {}
  public logout() {
    this.keycloak.logout(`${environment.FRONTEND_URL}/gateway/auth`);
  }
}
