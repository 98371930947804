<div class="inner-container">
  <mat-card class="card saml-card">
    <mat-card-title class="card-header">
      <h2 class="mt-0">Add SAML Provider</h2>
    </mat-card-title>
    <!-- <mat-card-content class="card-content">
  
      <form>
        <mat-form-field class="input">
          <mat-label>Redirect URI</mat-label>
          <input matInput>
        </mat-form-field>
        <mat-form-field class="input">
          <mat-label>Alias</mat-label>
          <input matInput>
        </mat-form-field>
        <mat-form-field class="input">
          <mat-label>Display Name</mat-label>
          <input matInput>
        </mat-form-field>
        <mat-form-field class="input">
          <mat-label>Endpoints</mat-label>
          <input matInput>
        </mat-form-field>
      </form>
    </mat-card-content> -->
    
    <!-- <mat-card-content class="card-content">
  
      <form>
        <mat-form-field class="input">
          <mat-label>Service Provider Entity</mat-label>
          <input matInput>
        </mat-form-field>
      </form>
    </mat-card-content> -->
  
    <!-- <mat-card-title class="card-header">
      <div class="ent-descriptor">
        <p class="header-text">Use Entity Descriptior</p>
        <mat-slide-toggle [hideIcon]="true" class="toggle"></mat-slide-toggle>
      </div>
    </mat-card-title> -->
    <mat-card-content class="card-content">
    <mat-card-title>
        <h3 class="mt-0">SAML Settings</h3>
      </mat-card-title>
      <mat-card-title class="card-header" *ngIf="isShown">
        <div class="header-text" *ngIf="isShown">Updated</div>
      </mat-card-title>
      <form #f="ngForm">
        <mat-form-field class="input">
          <mat-label>Redirect URI</mat-label>
          <input matInput [(ngModel)]="externalAuth.keycloakUrl" name="keycloakUrl" disabled/>
        </mat-form-field>
        <mat-form-field class="input">
          <mat-label >SAML Entity Descriptior</mat-label>
          <input matInput [(ngModel)]="externalAuth.entityDescriptor" name="entityDescriptor" required>
        </mat-form-field>
      </form>
    </mat-card-content>
    <div class="btn-group">
      <button mat-flat-button color="primary" class="primary-btn" [disabled]="!f.valid" (click)="addIdp()">Submit</button>
    </div>
  </mat-card>
</div>