<div class="content-container">
      <div class="card-header">
        <div class="user-icon">
          <img src="../../assets/user-square.png" width="24" height="24" alt="">
        </div>
        <h2 style="margin-left: 11px;">User Name</h2>
      </div>
      <hr class="seperater" />
      <form class="password-reset-form">
        <mat-form-field class="username-field">
          <mat-label>Username</mat-label>
          <input matInput />
        </mat-form-field>
  
        <mat-form-field class="password-field">
          <mat-label>Password</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'">
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
      </form>
      <div class="reset-button">
        <button mat-raised-button color="primary" style="border-radius: 25px; width:186px;background-color:#53A7FA">Reset
          Password</button>
      </div>
</div>