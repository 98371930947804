<h2 mat-dialog-title>Edit User</h2>
<mat-dialog-content>
  <div class="modal-inputs">
    <mat-form-field>
      <mat-label>User Name</mat-label>
      <input matInput readonly [value]="this.data.user_id" #usernameInput />
    </mat-form-field>
    <mat-form-field>
      <mat-label>E-mail Address</mat-label>
      <input matInput [value]="this.data.email_address" #emailInput />
    </mat-form-field>
    <mat-form-field>
      <mat-label>First Name</mat-label>
      <input matInput [value]="this.data.first_name" #firstNameInput />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Last Name</mat-label>
      <input matInput [value]="this.data.last_name" #lastNameInput />
    </mat-form-field>
    <mat-slide-toggle
      class="mat-accent"
      [checked]="this.data.tenant_admin"
      [hideIcon]="true"
      (change)="updateAdmin(data)"
      >Admin</mat-slide-toggle
    >
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="modal-btn-group">
    <button mat-button mat-dialog-close color="primary" class="secondary-btn mr-15 mb-15">Cancel</button>
    <button
      mat-flat-button
      mat-dialog-close
      cdkFocusInitial
      color="primary"
      class="primary-btn mr-15 mb-15"
      (click)="updateUser(this.data)"
    >
      Update User
    </button>
  </div>
</mat-dialog-actions>
