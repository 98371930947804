<h2 mat-dialog-title>Add a User</h2>
<mat-dialog-content>
  <div class="modal-inputs">
    <mat-form-field>
      <mat-label>User Name</mat-label>
      <input matInput #usernameInput>
    </mat-form-field>
    <mat-form-field>
      <mat-label>E-mail Address</mat-label>
      <input matInput #emailInput>
      </mat-form-field>
      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input matInput #firstNameInput>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Last Name</mat-label>
          <input matInput #lastNameInput>
        </mat-form-field>
        <mat-slide-toggle class="mat-accent" [hideIcon]="true" (change)="updateAdmin(data)">Admin</mat-slide-toggle>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="modal-btn-group">
    <button mat-dialog-close color="primary" class="secondary-btn mb-15 mr-15">
      Cancel
    </button>
      <button mat-flat-button (click)="addUser(this.data)" cdkFocusInitial color="primary" class="primary-btn mb-15 mr-15">
        Add User
      </button>
  </div>
</mat-dialog-actions>