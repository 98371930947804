import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsageComponent } from './usage/usage.component';
import { StatusComponent } from './status/status.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { UserComponent } from './user/user.component';
import { ExternalAuthComponent } from './external-auth/external-auth.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { AuthComponent } from './auth/auth.component';
import { AppComponent } from './app.component';
import { DarSettings } from './solutions/solutions-wizard/dar-settings/dar-settings.component';
//import { DarSettings } from './solutions/solutions-wizard/dar-settings/dar-settings.component';

const routes: Routes = [
  { path: '', redirectTo: 'gateway/auth', pathMatch: 'full' },
  { 
    path: 'gateway',
    component: AppComponent,
    children: [
      { path: 'home', redirectTo: 'home/dashboard', pathMatch: 'full', data: { breadcrumb: 'Dashboard' } },
      { 
        path: 'home', component: HomeComponent,
        children: [
          { path: 'dashboard', component: DashboardComponent, data: { breadcrumb: 'Dashboard' } },
          { path: 'usage', component: UsageComponent, data: { breadcrumb: 'Usage' } },
          { path: 'status', component: StatusComponent, data: { breadcrumb: 'Status' } },
          { path: 'solutions', component: SolutionsComponent},
          { path: 'user-management', component: UserManagementComponent, data: { breadcrumb: 'User Management' } },
          { path: 'user', component: UserComponent, data: { breadcrumb: 'Users' } },
          { path: 'external-auth', component: ExternalAuthComponent, data: { breadcrumb: 'External Authorization' } },
          { path: 'solutions/solutions-wizard/dar-settings', component: DarSettings },
        ]
      },
      { path: 'auth', component: AuthComponent },
    ]
  },
  { path: 'auth', component: AuthComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}