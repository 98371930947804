import { Component, OnInit} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import {CommonModule} from '@angular/common';
import { MatButtonModule} from '@angular/material/button';
import {CustomKeycloakAuthService} from '../custom-keycloak-auth.service';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';
import {ApiService} from '../api.service'
import {BrowserModule} from '@angular/platform-browser'
import { environment } from 'src/environments/environment';
import {ExternalAuth} from './external-auth'
import {
  MatSnackBar,
  MatSnackBarAction,
  MatSnackBarActions,
  MatSnackBarLabel,
  MatSnackBarModule,
  MatSnackBarRef,
} from '@angular/material/snack-bar';



@Component({
  selector: 'app-external-auth',
  standalone: true,
  templateUrl: './external-auth.component.html',
  imports: [ FormsModule,MatSelectModule, MatInputModule, MatFormFieldModule, MatCardModule, CommonModule, 
    MatSnackBarModule,MatSlideToggleModule, MatButtonModule, MatFormFieldModule, MatInputModule],
  styleUrls: ['./external-auth.component.css']
})
export class ExternalAuthComponent implements OnInit{
  
  public tenantId: string = sessionStorage.getItem("tenantId") as string;
  public token = sessionStorage.getItem("token") as string;

  constructor(  private readonly keycloak: KeycloakService,
    private customKeycloakAuthService: CustomKeycloakAuthService,
    private router: Router,
    private apiService: ApiService,
    private _snackBar: MatSnackBar ) {

  }
  isLoggedIn: Boolean=false;
  data: any
  update:Boolean=false
  isShown: Boolean=false
  keycloakUrl=environment.KEYCLOAK_URL+"/realms/"+this.tenantId+"/brokers/dsio/endpoint/descriptor"
  externalAuth: ExternalAuth = new ExternalAuth(this.keycloakUrl,'')

  async ngOnInit(): Promise<void> {
    // console.log('in init')
    const isLoggedIn =   await this.customKeycloakAuthService.isLoggedInWithDelay()
    this.isLoggedIn = isLoggedIn;
    const token = await this.customKeycloakAuthService.getToken();
    this.token= token; 
    sessionStorage.setItem("token", token);
    const tokenUpdated = await this.customKeycloakAuthService.updateToken2();
    if (tokenUpdated){
      console.log(`Token Updated`)
      sessionStorage.setItem("token", await this.customKeycloakAuthService.getToken())
    }else{
      console.log(`Token Not Updated`)
    }
    this.handleGetIDP()
  }

  async handleGetIDP() {
      let idp = this.apiService.getIDP(this.token, this.tenantId);
      await idp.subscribe( (idpResult: any) => {
            // console.log(idpResult);
            idp = idpResult;
          if(idpResult){
            this.externalAuth.entityDescriptor="Authentication Created: Enter new url to update"
            this.update=true
          }
         
        })
  }

  async addIdp(){
    let idp:any
    if(!this.update){
        idp = this.apiService.addIDP(this.token, this.externalAuth.entityDescriptor,this.tenantId)
        .subscribe(idp => {
          this.isShown=true;
          // console.log(idp);
          this.externalAuth.entityDescriptor="Authentication Created: Enter new url to update";
          this._snackBar.open("idp added","close")
        })
    }else{
        idp = this.apiService.updateIDP(this.token, this.externalAuth.entityDescriptor,this.tenantId)
        .subscribe(idp => {
          this.isShown=true;
          // console.log(idp);
          this.externalAuth.entityDescriptor="Authentication Created: Enter new url to update";
          this._snackBar.open("idp updated","close")
        })
    }
    await idp.subscribe( (idpResult: any) => {
    

    })
    
   

  }


}
