import { Component, OnInit } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { MatCardModule } from "@angular/material/card";

export interface Realm {
  displayName: string | null;
  clientId: string;
  name: string;
}

@Component({
  selector: "app-auth",
  standalone: true,
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.css"],
  imports: [MatInputModule, MatFormFieldModule, MatButtonModule, MatCardModule],
  providers: [KeycloakService],
})
export class AuthComponent implements OnInit {
  public tenantId: string = "";
  public isLoggedIn = false;

  constructor(
    private readonly keycloak: KeycloakService,
    private router: Router
  ) {
    const state = this.getParameterFromUrl("state");

    if (state) {
      this.router.navigate(["gateway/home"]);
    }
  }

  ngOnInit(): void {}

  updateTenantId(event: Event) {
    this.tenantId = (event.target as HTMLInputElement).value;
  }

  handleLoginPress() {
    this.login();
  }

  login() {
    const realm = this.tenantId;
    const clientId = "websiteclient";
    if (clientId && realm) {
      sessionStorage.setItem("tenantId", this.tenantId);
      this.keycloak
        .init({
          config: {
            realm: realm,
            url: environment.KEYCLOAK_URL,
            clientId: "websiteclient",
          },
          initOptions: {
            responseMode: "query",
          },
          /*initOptions: {
            onLoad: "check-sso",
            silentCheckSsoRedirectUri:
              window.location.origin + "/assets/silent-check-sso.html",
          },
          enableBearerInterceptor: true,
          bearerExcludedUrls: ["/assets"],
          shouldUpdateToken: (request) => {
            return !(request.headers.get("token-update") === "false");
          },*/
        })
        .then(() => {
          this.keycloak.login({
            //action: "login",
            redirectUri: `${environment.FRONTEND_URL}/gateway/auth`,
            //redirectUri: `${environment.FRONTEND_URL}/gateway/auth?action=return&clientid=${clientId}&realm=${realm}`,
          });
        });
    }
  }

  getParameterFromUrl(parameter: string) {
    const re = new RegExp(parameter + "=([a-z0-9-]+)&?");
    const results = re.exec(window.location.href);

    if (results) {
      return results[1];
    } else {
      return "";
    }
  }
}
