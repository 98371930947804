<div class="inner-container">
  <mat-progress-bar *ngIf="isloading" 
   mode="indeterminate" value="80" class="mb-15">
 </mat-progress-bar>
 <!-- <button mat-flat-button color="primary" class="primary-btn" routerLink="solutions-wizard/dar-settings">
   Configure
 </button> -->
   <table mat-table [dataSource]="dataSource" matSort>
     <!-- Solution Name Column -->
     <ng-container matColumnDef="solutionName">
       <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
       <td mat-cell *matCellDef="let element"> {{element.solution_id}} </td>
     </ng-container>

     <!-- Status Column -->
     <ng-container matColumnDef="status">
       <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
       <td mat-cell *matCellDef="let element"> {{element.status}} </td>
     </ng-container>

     <!-- Action Column -->
     <ng-container matColumnDef="action">
       <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
       <td mat-cell *matCellDef="let element">
         <!-- <ng-container *ngIf="element.action!=='request'">
           <mat-slide-toggle class="mat-accent" [checked]=" element.action" [labelPosition]="'after'"
             [hideIcon]="true" (change)="changeStatus($event,element.solutionId)">
             <p class='label'>Enabled</p>
           </mat-slide-toggle>
         </ng-container> -->
         <ng-container *ngIf="element.status==='Available'">
         <button mat-flat-button color="primary" class="primary-btn" (click)="viewDetails(false)" routerLink="./solutions-wizard/dar-settings" routerLinkActive="active" ariaCurrentWhenActive="page" #darsettings="routerLinkActive">
             Configure
           </button>
         </ng-container>
         <ng-container *ngIf="element.status != 'Available'">
           <button mat-flat-button [disabled]="" color="primary" (mouseup)="viewDetails(true)" routerLink="./solutions-wizard/dar-settings"  class="secondary-solid-btn" routerLinkActive="active" ariaCurrentWhenActive="page" #darsettings="routerLinkActive">
             View
           </button>
         </ng-container>
         </td>
     </ng-container>
     <tr class="header-shadow" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
     <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
   </table>
 </div>