<div class="container">
  <div class="logo-container mb-15">
    <img src="../../assets/datastorioLogo.png" width="180">
  </div>
  <mat-card class="card p-15">
    <mat-card-title>
      <div class="card-header">
        <h2>Enter Your Tenant ID</h2>
      </div>
    </mat-card-title>
    <mat-card-content class="p-15">
      <mat-form-field class="inpt">
        <mat-label>Tenant Id</mat-label>
        <input matInput (change)="updateTenantId($event)">
      </mat-form-field>
    </mat-card-content>
    <div class="btn-container">
      <button class="primary-btn mr-10 mb-10" mat-flat-button color="primary" (click)="handleLoginPress()">Login</button>
    </div>
  </mat-card>


</div>