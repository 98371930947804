import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { environment } from '../environments/environment';
import { Router } from "@angular/router";
import { CustomKeycloakAuthService } from './custom-keycloak-auth.service';
import { Realm } from './auth/auth.component';


export enum ActionType {
    login= "login",
    register= "register",
    return= "return",
  }

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'dsio-tenant-portal-client';

  public isLoggedIn = false;
  public userProfile: KeycloakProfile | null = null;

  
  environment: any = environment;

  public firstName: String|undefined = '';

    constructor(private readonly keycloak: KeycloakService, private customKeycloakAuthService: CustomKeycloakAuthService, private router: Router) {
    }

    ngOnInit() {
      }
    public logout() {
      this.customKeycloakAuthService.keycloakInstance.logout().then()
    }
    
}
