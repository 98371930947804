import { Component } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { KeycloakService } from "keycloak-angular";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { UserService } from "@kelyn-technologies/dsio-angular-lib/dsio-api";

@Component({
  selector: "app-user",
  standalone: true,
  templateUrl: "./user.component.html",
  imports: [
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatProgressBarModule,
    MatIconModule,
  ],
  styleUrls: ["./user.component.css"],
})
export class UserComponent {
  isloading: boolean = true;
  public hide = true;

  constructor(
    private userService: UserService,
    private readonly keycloak: KeycloakService
  ) {}

  // async handleGetUsers() {
  //     this.users$ = this.apiService.getUsers(await this.keycloak.getToken());
  //     await this.users$.subscribe({
  //       next(response) {
  //         response.forEach((user:User) => {
  //           alert(JSON.stringify(user));
  //         });

  //       },
  //       error(err) {
  //         console.log(err)
  //       },
  //     })
  // }
}
