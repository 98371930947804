import { Component, OnInit, Output, Input } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ApiService } from '../api.service';
import { Observable, Subscription } from 'rxjs';
import { RouterModule, Router } from '@angular/router';
import { BarGraphComponent } from 'dsio-ui-library';
import {PieGraphComponent} from 'dsio-ui-library';
import {MatCardModule} from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import {CustomKeycloakAuthService} from '../custom-keycloak-auth.service';
import {CommonModule} from '@angular/common';
import {MatProgressSpinnerModule,MatProgressSpinner} from '@angular/material/progress-spinner'
import {MatProgressBarModule} from '@angular/material/progress-bar'

@Component({
  selector: 'app-dashboard',
  standalone: true,
  templateUrl: './dashboard.component.html',
  imports: [
    BarGraphComponent,
    PieGraphComponent,
    MatCardModule,
    MatButtonModule,
    RouterModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatProgressBarModule
  ],
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit{
  testfield:string = "test"
  isloading:boolean = true
  entitlements$!: Observable<Array<any>|any>
  solutions$!: Observable<Array<any>|any> 

  top3Usage$!: Observable<Array<any>|any>

  ytdUsage$!: Observable<Array<any>|any>

  @Output() entitlements: any;
  
  @Output() top3Usage: any;

  public ytdUsage: any;

  public isLoggedIn = false;
  public tenantId: string = sessionStorage.getItem("tenantId") as string;
  public token: string = sessionStorage.getItem("token") as string;

 down:any = 0;
 errorCount:any = 0;
 total:any=0;

  constructor(    
    private readonly keycloak: KeycloakService,
    private customKeycloakAuthService: CustomKeycloakAuthService,
    private router: Router, private apiService: ApiService) {
  }

  async ngOnInit(): Promise<void> {
    
    // this.getEntitlements()
    // this.handleGetStatus()
    this.getTop3Usage()
    this.getYTDUsage()
    this.isloading=false;
  
    const isLoggedIn = await this.customKeycloakAuthService.isLoggedInWithDelay()
    this.isLoggedIn = isLoggedIn;
    const token = await this.customKeycloakAuthService.getToken();
    this.token= token; 
    sessionStorage.setItem("token", token);
    const tokenUpdated = await this.customKeycloakAuthService.updateToken2();
    if (tokenUpdated){
      console.log(`Token Updated`)
      sessionStorage.setItem("token", await this.customKeycloakAuthService.getToken())
    }else{
      console.log(`Token Not Updated`)
    }


  }

// public checkEntitlements(){
  //console.log(this.entitlements)
//}
//  async getEntitlements() {
//     this.entitlements$ = this.apiService.getUsage(this.token, this.tenantId)
//     this.entitlements$.subscribe((entitlements: any) => {
//       this.entitlements = entitlements;
//       this.isloading=false;
//       console.log("entitlements: " + this.entitlements)
//     });

//   }

  async getTop3Usage() {
    this.top3Usage$ = this.apiService.getTop3Usage(this.token, this.tenantId)
    this.top3Usage$.subscribe((top3Usage: any) => {
      this.top3Usage = top3Usage;
      
    });
  }

  async getYTDUsage() {
    this.ytdUsage$ = this.apiService.getYtdUsage(this.token, this.tenantId)
    this.ytdUsage$.subscribe((ytdUsage: any) => {
      this.ytdUsage = ytdUsage;
      // console.log("ytdUsage in dashboard:", this.ytdUsage)
    });
  }

  async handleGetStatus() {
  //   this.solutions$ = this.apiService.getTenantSolutions(this.token, this.tenantId);
  //   await this.solutions$.subscribe( (solutions: any) => {
  //     const solutionValues = Object.values(solutions);
  //           solutionValues.forEach((resources: any) =>{
  //             resources.forEach((resource: any) =>{
  //             this.total++;
  //             if(resource.errors){
  //               this.errorCount+=resource.errors.length
  //             }
  //             if(resource.status!=='available' && resource.status){
  //               this.down++;
  //             }
  //           })
  //         })
  // })
  // console.log(`Total: ${this.total}`)
  // console.log(`Down: ${this.down}`)
  // console.log(`Error Count: ${this.errorCount}`)  
  }

  goToStatus() {
    this.router.navigate(['/status']);
  }

  getRepeatArray(times: number): number[] {
    return Array(times).fill(0);  // Creates an array with 'times' elements
  }
  
}
