<mat-card class="card-min-height">
  <!-- <mat-card-header> -->
  <mat-card-title class="pt-15">
    <div class="card-header p-20 mt-10">
        <img class="d-ifx mr-10" src="assets/system-status-icon.png" height="35px" width="35px" alt="System Status">
        <p class="d-ifx m-0">System Status</p>
        <div class="status-container">
          <ng-container *ngIf="this.errorCount > 0">
            <img class="d-ifx" src="assets/danger-circle.png" height="35px" width="35px" />
          </ng-container>
          <ng-container *ngIf="this.errorCount === 0">
            <img class="d-ifx" src="assets/tick-circle.png" height="35px" width="35px" />
          </ng-container>
        </div>
      </div>
    </mat-card-title>
    <!-- </mat-card-header> -->
  <mat-card-content class="">
    <p class="status-content-txt">Status detail to go here. If it is really long it can break to two lines and keep on
      going.</p>
  </mat-card-content>
  <mat-card-footer>
    <div>
      <div class="footer-list">
        <div class="good footer">
          <p class="dot">&#x2022;</p>
          <p class="footer-txt">{{this.total-this.down}}/{{this.total}} Online</p>
        </div>
        <div class="warning footer">
          <p class="dot">&#x2022;</p>
          <p class="footer-txt">{{this.errorCount}} Issues</p>
        </div>
        <div class="bad footer">
          <p class="dot">&#x2022;</p>
          <p class="footer-txt">{{this.down}} Down</p>
        </div>
      </div>
    </div>
  </mat-card-footer>
  </mat-card>