import {CommonModule} from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {ApiService} from '../api.service'
import {Observable} from 'rxjs';
import { Status } from '../status/status.component';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';

@Component({
  selector: 'app-system-status-card',
  standalone: true,
  templateUrl: './system-status-card.component.html',
  imports: [MatCardModule, MatExpansionModule, CommonModule],
  styleUrls: ['./system-status-card.component.css']
})
export class SystemStatusCardComponent implements OnInit{

  solutions$!: Observable<Array<Status>|any> 
  @Input() solutions: any;
  errorCount:any = 0;
  errors:any = 0;  
  down:any = 0;

  total:any=0;
  public isExpanded:boolean = false;
  issue: any;
  footer: any;


  public token = sessionStorage.getItem("token") as string;
  public tenantId = sessionStorage.getItem("tenantId") as string;

  constructor(private apiService: ApiService) { 
  }

ngOnInit(): void {
  this.handleGetStatus()
  
}
  handleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  async handleGetStatus() {
    this.solutions$ = this.apiService.getResources(this.token, this.tenantId);
    await this.solutions$.subscribe( (solutions: any) => {
      const solutionValues = Object.values(solutions);
      
          
        
            solutionValues.forEach((resources: any) =>{
              resources.forEach((resource: any) =>{
              this.total++;
              if(resource.errors){
                this.errorCount+=resource.errors.length
              }
              if(resource.status!=='available' && resource.status){
                this.down++;
              }
            })
          })
  })
  }
  
}
