<!-- <div style="width:100%; margin-left: -60px">
  <div class="content-container">
    <div class="header-container">
      <app-system-status-card class="card" [solutions]="solutions | keyvalue" />
      <app-system-messages-card class="card" />
      <app-system-support-card class="card" />
    </div>
  </div>
  <div class="separator">
  </div>
  <div class="grid-container">
    <mat-progress-bar *ngIf="isloading" 
      mode="indeterminate" value="80">
    </mat-progress-bar>
    <ng-container *ngFor="let solution of solutions | keyvalue">
      <app-system-category-card class="card" [solution]=solution/>
    </ng-container>
  </div>
</div> -->

<!-- <div style="width:100%; margin-left: -60px"> -->
  <div class="inner-container"> 
    <mat-progress-bar *ngIf="isloading" 
      mode="indeterminate" value="80">
    </mat-progress-bar>
    <div class="sticky-top-container">
      <app-system-status-card class="mr-20 w-33" [solutions]="solutions" />
      <app-system-messages-card class="mr-20 w-33" />
      <app-system-support-card class="w-33"/>
    </div>
  </div>
  <div class="separator"></div>
  <div class="inner-container">
    <div class="grid-container">
      <ng-container *ngFor="let solution of solutions | keyvalue">
        <app-system-category-card class="w-33 mr-20" [solution]=solution/>
      </ng-container>
  </div>
  </div>
  
<!-- </div> -->