import { Component, OnInit, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import {
  UsageCardComponent,
  YearlyUsageByMonth,
} from "@kelyn-technologies/dsio-angular-lib";
import { CommonModule } from "@angular/common";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import {
  UsageService,
  SolutionService,
  Solution,
  Usage,
} from "@kelyn-technologies/dsio-angular-lib/dsio-api";

@Component({
  selector: "app-usage",
  standalone: true,
  templateUrl: "./usage.component.html",
  imports: [
    MatButtonModule,
    UsageCardComponent,
    CommonModule,
    MatProgressBarModule,
  ],
  styleUrls: ["./usage.component.css"],
})
export class UsageComponent implements OnInit {
  isloading: boolean = true;
  @Output() public allUsage: YearlyUsageByMonth[] = [];
  constructor(
    private readonly usageService: UsageService,
    private readonly solutionService: SolutionService
  ) {}

  async ngOnInit(): Promise<void> {
    const solutions = this.getSolutions();

    solutions.forEach((solution) => {
      const usageByDimension = new Map<string, Usage[]>();
      this.usageService
        .getSolutionUsage(solution.solution_id)
        .subscribe((response) => {
          response.usage?.reverse();
          response.usage?.slice(0, 11).forEach((item) => {
            if (usageByDimension.has(item.dimension)) {
              usageByDimension.get(item.dimension)?.push(item);
            } else {
              usageByDimension.set(item.dimension, [item]);
            }
          });

          usageByDimension.forEach((usage, dimension) => {
            this.allUsage.push(
              new YearlyUsageByMonth(solution.name, dimension, usage)
            );
          });
        });
    });
  }

  getSolutions(): Solution[] {
    let solutions = [] as Solution[];
    this.solutionService.getSolutions().subscribe((result) => {
      solutions = result.solutions;
    });
    return solutions;
  }
}
