import { Component, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { KeycloakService } from "keycloak-angular";
import { SystemMessagesCardComponent } from '@kelyn-technologies/dsio-angular-lib';
import { SystemStatusCardComponent } from "../system-status-card/system-status-card.component";
import { SystemSupportCardComponent } from '@kelyn-technologies/dsio-angular-lib';
import { SystemCategoryCardComponent } from '@kelyn-technologies/dsio-angular-lib';
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { SolutionService, Solution} from "@kelyn-technologies/dsio-angular-lib/dsio-api";

export interface Status {
  solutionName: string;
  resources: Array<ResourceStatus>;
}

export interface ResourceError {
  errorType: string;
  errorDescription: string;
}
export interface ResourceStatus {
  resourceType: string;
  componentName: string;
  ownerId: string;
  errors?: Array<ResourceError>;
}

@Component({
  selector: "app-status",
  standalone: true,
  templateUrl: "./status.component.html",
  imports: [
    MatButtonModule,
    SystemMessagesCardComponent,
    SystemStatusCardComponent,
    SystemSupportCardComponent,
    MatProgressBarModule,
    SystemCategoryCardComponent,
    CommonModule,
  ],
  styleUrls: ["./status.component.css"],
})
export class StatusComponent implements OnInit {
  isloading: boolean = true;
  @Output() solutions: Solution[] = [];
  public tenantId = sessionStorage.getItem("tenantId") as string;
  constructor(
    private readonly solutionService: SolutionService,
    private readonly keycloak: KeycloakService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.handleGetStatus();
  }

  async handleGetStatus() {
    this.solutionService.getSolutions().subscribe((result) => {
      this.isloading = false;
      this.solutions = result.solutions ? result.solutions : []
    })
  }
}
