import {
  AfterViewInit,
  OnInit,
  Component,
  ViewChild,
  Inject,
  ElementRef,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { KeycloakService } from "keycloak-angular";
import { Observable } from "rxjs";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from "@angular/material/slide-toggle";
import { MatSort, MatSortModule } from "@angular/material/sort";
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { CommonModule } from "@angular/common";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import {
  UserService,
  User,
  UserRole,
} from "@kelyn-technologies/dsio-angular-lib/dsio-api";

@Component({
  selector: "app-user-management",
  standalone: true,
  imports: [
    MatButtonModule,
    MatTableModule,
    MatSlideToggleModule,
    MatSortModule,
    CommonModule,
    MatProgressBarModule,
    MatDialogModule,
    MatSnackBarModule,
  ],
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.css"],
})
export class UserManagementComponent implements AfterViewInit, OnInit {
  isloading: boolean = true;

  tenantId: string = sessionStorage.getItem("tenantId") as string;
  isLoggedIn = false;
  USER_DATA: User[] = [];
  displayedColumns: string[] = [
    "userName",
    "emailAddress",
    "lastName",
    "isAdmin",
  ];
  dataSource = new MatTableDataSource<any>();

  editUserId: string = "";
  editUsername: string = "";
  editEmailAddress: string = "";
  editFirstName: string = "";
  editLastName: string = "";
  editIsAdmin: boolean = false;

  public selectedRow: string = "1";
  // constructor(public dialog: MatDialog){}
  constructor(
    private userService: UserService,
    private readonly keycloak: KeycloakService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  @ViewChild(MatSort) sort!: MatSort;

  async ngOnInit(): Promise<void> {
    this.handleGetUsers();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  preventToggle(event: MatSlideToggleChange, element: any) {
    event.source.checked = element.isAdmin;
  }

  setSelected(row: User): void {
    this.selectedRow = row.user_id ? row.user_id : "";
    this.editUserId = row.user_id ? row.user_id : "";
    this.editUsername = row.user_id ? row.user_id : "";
    this.editEmailAddress = row.email_address ? row.email_address : "";
    this.editFirstName = row.first_name ? row.first_name : "";
    this.editLastName = row.last_name ? row.last_name : "";
    this.editIsAdmin = row.tenant_admin as boolean;
  }

  getSelected(userId: string): User {
    let user: User = {
      user_id: "",
      username: "",
      email_address: "",
      first_name: "",
      last_name: "",
      tenant_admin: false,
    };
    this.USER_DATA.forEach((row: User) => {
      if (row.user_id === userId) {
        user = row;
      }
    });
    return user;
  }

  handleAddNewUserDialog(): void {
    const dialogRef = this.dialog.open(AddUserDialog, {
      width: "342px",
      data: {
        userId: "",
        userName: "",
        emailAddress: "",
        firstName: "",
        lastName: "",
        isAdmin: false,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.handleAddUser(result);
    });
  }

  handleEditUserDialog(userId: string): void {
    const dialogRef = this.dialog.open(EditUserDialog, {
      width: "342px",
      data: {
        userId: this.editUserId,
        userName: this.editUsername,
        emailAddress: this.editEmailAddress,
        firstName: this.editFirstName,
        lastName: this.editLastName,
        isAdmin: this.editIsAdmin,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.handleEditUser(result);
      this.handleUpdateRole(result);
      this.USER_DATA.forEach((row) => {
        if (row.user_id === result.userId) {
          row.user_id = result.userName;
          row.email_address = result.emailAddress;
          row.first_name = result.firstName;
          row.last_name = result.lastName;
          row.tenant_admin = result.isAdmin;
        }
      });
    });
  }

  async handleAddUser(user: any): Promise<void> {
    const request = this.userService.createUser(user);
    request.subscribe((data: any) => {
      // console.log('Post Call Successful', data);
      this.handleUpdateRole(user);
      this._snackBar.open("User Added", "close");
      user.userId = data;
      console.table(user);
      this.USER_DATA.push(user);
      this.dataSource.data = this.USER_DATA;
    });
  }

  async handleEditUser(data: any): Promise<void> {
    this.userService.updateUser(data).subscribe((data: any) => {
      this._snackBar.open("User Updated", "close");
      // console.log('Patch Call Successful', data);
    });
  }

  async getRole(userId: string, index: number) {
    const request = this.userService.getUserRoles(userId);
    request.subscribe((data: any) => {
      if (data.length > 0) {
        this.USER_DATA[index].tenant_admin = true;
      }
    });
  }

  async handleUpdateRole(data: User): Promise<void> {
    this.userService
      .updateUserRoles("", {} as UserRole)
      .subscribe((data: any) => {
        this._snackBar.open("Role Updated", "close");
        // console.log('Patch Call Successful', data);
      });
  }

  async handleGetUsers() {
    this.isloading = true;
    if (this.USER_DATA.length > 0) {
      this.USER_DATA = [];
    }
    const request = this.userService.getUsers();
    request.subscribe((data: any) => {
      this.isloading = false;

      data.forEach(async (user: User, index: number) => {
        const newuser = {
          user_id: user.user_id,
          username: user.username,
          email_address: user.email_address,
          first_name: user.first_name,
          last_name: user.last_name,
          tenant_admin: false,
        };
        if (user.tenant_admin) {
          newuser.tenant_admin = true;
        } else {
          newuser.tenant_admin = false;
        }
        this.USER_DATA.push(newuser);
        this.getRole(user.user_id, index);
      });
      this.dataSource.data = this.USER_DATA;
    });
  }
}

@Component({
  selector: "dsio-add-user-dialog",
  templateUrl: "./dsio-add-user-dialog.html",
  styleUrls: ["./user-management.component.css"],
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogModule,
    CommonModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatSlideToggleModule,
  ],
})
export class AddUserDialog {
  @ViewChild("usernameInput") usernameInput!: ElementRef;
  @ViewChild("emailInput") emailInput!: ElementRef;
  @ViewChild("firstNameInput") firstNameInput!: ElementRef;
  @ViewChild("lastNameInput") lastNameInput!: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<AddUserDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  addUser(data: any): void {
    data.userName = this.usernameInput.nativeElement.value;
    data.firstName = this.firstNameInput.nativeElement.value;
    data.lastName = this.lastNameInput.nativeElement.value;
    data.emailAddress = this.emailInput.nativeElement.value;
    data.isAdmin = this.data.isAdmin;
    this.dialogRef.close(data);
  }

  updateAdmin(data: any): void {
    this.data.isAdmin = !this.data.isAdmin;
  }
}

@Component({
  selector: "dsio-edit-user-dialog",
  templateUrl: "./dsio-edit-user-dialog.html",
  styleUrls: ["./user-management.component.css"],
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogModule,
    CommonModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatSlideToggleModule,
  ],
})
export class EditUserDialog {
  role$!: Observable<any>;
  @ViewChild("usernameInput") usernameInput!: ElementRef;
  @ViewChild("emailInput") emailInput!: ElementRef;
  @ViewChild("firstNameInput") firstNameInput!: ElementRef;
  @ViewChild("lastNameInput") lastNameInput!: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<EditUserDialog>,
    @Inject(MAT_DIALOG_DATA) public data: User
  ) {}
  async updateUser(data: any): Promise<void> {
    data.firstName = this.firstNameInput.nativeElement.value;
    data.lastName = this.lastNameInput.nativeElement.value;
    data.emailAddress = this.emailInput.nativeElement.value;

    this.dialogRef.close(data);
  }

  updateAdmin(data: any): void {
    this.data.tenant_admin = !this.data.tenant_admin;
  }
}
