import { Component,OnInit} from '@angular/core';
import { environment } from '../../environments/environment';
import { RouterModule, Router} from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import {CommonModule} from '@angular/common';
import { AuthComponent } from "../auth/auth.component";
import { CustomKeycloakAuthService } from '../custom-keycloak-auth.service';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import {MatProgressSpinnerModule,MatProgressSpinner} from '@angular/material/progress-spinner'
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BreadcrumbComponent } from 'dsio-ui-library';

@Component({
  selector: 'app-home',
  standalone: true,
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  imports: [RouterModule, AuthComponent, MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, MatGridListModule, MatCardModule, MatMenuModule, CommonModule,
  MatProgressSpinnerModule, BreadcrumbComponent]
})
export class HomeComponent implements OnInit{



  public isLoggedIn = false;
  public userProfile: KeycloakProfile | null = null;
  public firstName: String|undefined = '';

  public token: string = sessionStorage.getItem("token") as string;
  environment: any = environment;

  public tenantId: string = sessionStorage.getItem("tenantId") as string;
  constructor(
    private readonly keycloak: KeycloakService,
    private customKeycloakAuthService: CustomKeycloakAuthService,
    private router: Router) {
}

  async ngOnInit() {
    await this.customKeycloakAuthService.initializeKeycloak({ name: this.tenantId, clientId: 'websiteclient', displayName: null })
  }
  public logout() {
    this.isLoggedIn = false;
    this.customKeycloakAuthService.keycloakInstance.logout(`${environment.FRONTEND_URL}/gateway/auth`);

}





}
